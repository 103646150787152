import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import en from './translations/en.json'
import ja from './translations/ja.json'
import es from './translations/es.json'
import fr from './translations/fr.json'
import pt from './translations/pt.json'
import ko from './translations/ko.json'
import nl from './translations/nl.json'
import ro from './translations/ro.json'

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    lng: 'en-US',
    fallbackLng: 'en-US',

    interpolation: {
      escapeValue: false // react already safes from xss
    },
    debug: process.env.NODE_ENV === 'development',
    resources: {}
  })
i18n.addResourceBundle('en', 'translation', en)
i18n.addResourceBundle('ja', 'translation', ja)
i18n.addResourceBundle('es', 'translation', es)
i18n.addResourceBundle('fr', 'translation', fr)
i18n.addResourceBundle('pt', 'translation', pt)
i18n.addResourceBundle('ko', 'translation', ko)
i18n.addResourceBundle('nl', 'translation', nl)
i18n.addResourceBundle('ro', 'translation', ro)

export default i18n
