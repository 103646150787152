import React, { useContext } from 'react'
import { UneeqContext } from 'uneeq-react-core'
import CircularProgress from '@material-ui/core/CircularProgress'
import styles from './styles'

const ThinkingLoader: React.FC = () => {
  const { config } = useContext(UneeqContext)

  const id = 'thinking-loader'

  const Loader = () => {
    if (config.avatarThinkingLoader) {
      return <config.avatarThinkingLoader id={id} />
    } else {
      return <CircularProgress id={id} style={styles.circularProgress} />
    }
  }

  return (
    <>
      <div />
      <Loader></Loader>
      <div />
    </>
  )
}

export default ThinkingLoader
