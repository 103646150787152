import React, { useContext } from 'react'
import { UneeqContext, useUneeqState } from 'uneeq-react-core'
import InfoIcon from '@material-ui/icons/Info'
import { ThemeProvider, createMuiTheme } from '@material-ui/core/styles'
import { styles as s } from './styles'
import IconButton from '@material-ui/core/IconButton'

const styles = s.savedItems

const muiTheme = createMuiTheme({
  palette: {
    type: 'dark',
    primary: {
      main: '#ffffff'
    },
    secondary: {
      main: '#ffffff'
    },
    contrastThreshold: 3,
    tonalOffset: 0.2
  }
})

const MobileInformationButton = () => {
  const { onScreenInfo } = useUneeqState()
  const { dispatch } = useContext(UneeqContext)
  const expand = () =>
    dispatch({ type: 'openMobileInformation', payload: true })

  if (!onScreenInfo.information) {
    return null
  }

  return (
    <ThemeProvider theme={muiTheme}>
      <IconButton
        aria-label="More Information"
        data-testid="mobileInfoButton"
        onClick={expand}
      >
        <InfoIcon
          style={{
            fill: '#ffffff'
          }}
        />
      </IconButton>
    </ThemeProvider>
  )
}

export default MobileInformationButton
