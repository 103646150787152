import React, { useContext, useState } from 'react'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import CircularProgress from '@material-ui/core/CircularProgress'
import { UneeqContext, useIsSmallScreen } from 'uneeq-react-core'
import { ThemeProvider, createMuiTheme } from '@material-ui/core/styles'
import { UtteranceListItem } from '../OnScreenInfo/InformationContent'
import styles from './styles'

interface UtteranceMenuButtonOptions {
  label: string
  items: UtteranceListItem[]
  key?: number
}

const UtteranceMenuButton: React.FC<UtteranceMenuButtonOptions> = ({
  label,
  items
}) => {
  if (!items?.length) return null

  const { sendText, dispatch } = useContext(UneeqContext)
  const [sending, setSending] = useState(false)
  const [utterance, setUtterance] = useState('')
  const isSmallScreen = useIsSmallScreen()
  const timerRef = React.useRef<number>()

  const muiTheme = createMuiTheme({
    palette: {
      type: 'dark',
      primary: {
        main: '#ffffff'
      },
      secondary: {
        main: '#ffffff'
      },
      contrastThreshold: 3,
      tonalOffset: 0.2
    },
    typography: {
      fontFamily: "'Varela Round', system-ui, sans-serif",
      fontSize: isSmallScreen ? 14 : 24
    }
  })

  React.useEffect(
    () => () => {
      clearTimeout(timerRef.current)
    },
    []
  )

  const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    const utterance = event.target.value as string
    setUtterance(utterance)

    setSending(true)
    timerRef.current = window.setTimeout(() => {
      setSending(false)
    }, 10000)
    sendText(utterance)
  }

  return (
    <ThemeProvider theme={muiTheme}>
      <FormControl
        color="primary"
        style={{
          ...styles.formControl,
          minWidth: isSmallScreen ? '100%' : 400
        }}
      >
        <InputLabel htmlFor="utterance-list-menu">{label}</InputLabel>
        <Select
          id="utterance-list-menu"
          label={label}
          onChange={handleChange}
          disabled={sending}
          value={utterance}
        >
          {items.map((child, index) => (
            <MenuItem value={child.utterance} key={index}>
              {child.label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      {sending && <CircularProgress />}
    </ThemeProvider>
  )
}

export default UtteranceMenuButton
