import React from 'react'
import { Flex } from 'rebass'
import styles from './styles'
import loadingSpinner from '../assets/img/loading-spinner.gif'

const Loading = () => {
  return (
    <Flex
      sx={{
        flex: 1,
        alignItems: 'center',
        justifyContent: 'center',
        background: 'white'
      }}
    >
      <img src={loadingSpinner} />
    </Flex>
  )
}

export default Loading