import React, { useContext } from 'react'
import { UneeqContext, useUneeqState } from 'uneeq-react-core'
import { Box, Button, Flex, Image, Text } from 'rebass'
import InformationContent, { InformationProps } from './InformationContent'
import { ReactComponent as ExpandIcon } from '../../assets/img/expand.svg'
import { ReactComponent as HeartIcon } from '../../assets/img/heart.svg'
import { ReactComponent as HeartFullIcon } from '../../assets/img/heart-full.svg'
import { styles as s } from './styles'
import { ReactComponent as CloseIcon } from '../../assets/img/close.svg'
import { useTranslation } from 'react-i18next'

import hash from 'hash-sum'

const styles = s.information

const Information: React.FC<InformationProps> = ({ information }) => {
  const { dispatch } = useContext(UneeqContext)
  const expand = () => dispatch({ type: 'expandInformation' })
  const { t } = useTranslation()
  const { savedItems } = useUneeqState()
  const { hiddenUI, mobileInformationOpen } = useUneeqState()

  const save = () => {
    dispatch({
      type: 'saveInformation'
    })
  }

  const hide = () => {
    dispatch({ type: 'openMobileInformation', payload: false })
  }

  return (
    <>
      <Flex sx={styles.closeButtonContainer}>
        {!hiddenUI && mobileInformationOpen && (
          <Button sx={styles.closeButton} variant="unstyled" onClick={hide}>
            <Image as={CloseIcon} alt="" />
          </Button>
        )}
      </Flex>
      <Flex sx={styles.content}>
        <Flex sx={styles.scroll}>
          <InformationContent information={information} />
        </Flex>
      </Flex>
    </>
  )
}

export default Information
