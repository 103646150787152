import React from 'react'
import { Flex, Text, Button } from 'rebass'
import { trackHandler } from 'uneeq-react-core'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'
import { ThemeProvider, createMuiTheme } from '@material-ui/core/styles'
import { ReactComponent as ExclamationIcon } from '../../assets/img/exclamation.svg'
import { styles as s } from './styles'
const styles = s.error

interface ErrorProps {
  message?: string
  clearError?: () => void
}

const muiTheme = createMuiTheme({
  palette: {
    type: 'light',
    primary: {
      main: '#ffffff'
    },
    secondary: {
      main: '#ffffff'
    },
    contrastThreshold: 3,
    tonalOffset: 0.2
  }
})

const Error: React.FC<ErrorProps> = ({
  message = `An unknown error ocurred`,
  clearError
}) => {
  return (
    <Flex sx={styles.container}>
      <Flex sx={styles.sideBorderError} />
      <Flex sx={styles.warning}>
        <Flex sx={styles.headingContainer}>
          <Flex>
            <Flex sx={styles.icon}>
              <ExclamationIcon />
            </Flex>
            <Text sx={styles.heading}>Error</Text>
          </Flex>
          <Flex sx={styles.closeContainer}>
            {clearError && (
              <ThemeProvider theme={muiTheme}>
                <IconButton
                  aria-label="delete"
                  onClick={trackHandler(clearError, 'close-error-btn')}
                >
                  <CloseIcon />
                </IconButton>
              </ThemeProvider>
            )}
          </Flex>
        </Flex>
        <Text sx={styles.message}>{message}</Text>
      </Flex>
    </Flex>
  )
}

export default Error
