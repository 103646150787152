import React from 'react'
import ReactDOM from 'react-dom'
import ReactGA from 'react-ga'
import './index.css'
import App from './app/App'
import { CookiesProvider } from "react-cookie";
import * as serviceWorker from './serviceWorker'
import config from './config'

if (config.analyticsID !== '') {
  ReactGA.initialize(config.analyticsID, {
    debug: config.analyticsDebug
  })
}

ReactDOM.render(<CookiesProvider><App /></CookiesProvider>, document.getElementById('root'))

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
