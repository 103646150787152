import React, { useEffect, useState } from 'react'
import { Flex, Text } from 'rebass'
import $ from 'jquery'
import styles from './styles'

interface CaptionsProps {
  captions: string
}

interface Caption {
  text: string
  words: string[]
  endTime: number
  captionDuration: number
  pauseDuration: number
}

interface Sentence {
  pauseDuration: number
  text: string
  ssml?: XMLDocument
}

const regexpressions = {
  sentences: new RegExp(/[^.,?!]+[.,!?]+[\])'"`’”]*/g),
  words: new RegExp(/\S+/g),
  breakMilliseconds: new RegExp(/(.*)ms/i),
  breakSeconds: new RegExp(/(.*)s/i),
  ssmlBreaks: new RegExp(/(<break(.*?)(['"\d\w\s])\/>)/gi),
  speakTagsToRemove: new RegExp(/<*[^>]speak[^>]*>/gi)
}

/**
 * List of SSML tags to strip out from responses when calculating sentences.
 * Removal of <speak> tag is not supported as it is the required root note of the SSML response.
 */
const ssmlTagsToRemove = [
  's',
  'p',
  'uneeq:happy',
  'uneeq:empathetic',
  'uneeq:excited',
  'uneeq:joking',
  'uneeq:agreeing',
  'uneeq:enquiring',
  'uneeq:neutral',
  'amazon:domain'
]

/**
 * Speaking rate of digital human.
 *
 * Adjust depending on the current SSML prosody of the overall response text.
 * Otherwise assumed to be 130 words per minute.
 *
 * normal = 130 wpm
 * faster = 160 wpm
 * slower = 100 wpm
 */
const speakingRate = 170

/**
 * The interval the timer tick() method runs - lower values indicate more responsive captions
 */
const timerInterval = 250

/**
 * Strips SSML tags from given string
 *
 * @param ssmlText Text to strip tags from
 *
 * @todo Add tests for this method and move into utility class
 */
const stripSSMLTags = (ssmlText: string, tags: string[]) => {
  const withNamespace = ssmlText
    .replace(
      '<speak>',
      '<speak xmlns="http://www.w3.org/2001/10/synthesis" version="1.0" xmlns:uneeq="uneeq" xmlns:amazon="amazon">'
    )
    .trim()

  const parser = new DOMParser()

  const ssml = parser.parseFromString(withNamespace, 'text/xml')
  if (ssml.getElementsByTagName('parsererror').length) {
    console.warn(ssml)
    console.info(ssmlText)
    return ssmlText
  }

  const $ssmlQuery = $(ssml)
  const $allTags = $ssmlQuery.find('*')

  $allTags.each(function() {
    const $target = $(this as any)

    ssmlTagsToRemove.forEach(tag => {
      const targetNode = $target[0]
      const tagName = targetNode.nodeName

      if (tag === tagName) {
        const $children = $target.children()

        if (targetNode.nodeValue) {
          // Tag has a text value, remove the tag and replace it with a TextNode containing the text value
          $target.replaceWith(targetNode.nodeValue)
        } else if ($children.length > 0) {
          // Tag has child XML nodes, remove the parent and move children up one level
          $children.eq(0).unwrap()
        } else {
          // Tag is empty or self closing - remove it
          $target.remove()
        }
      }
    })
  })

  const strippedSSML = new XMLSerializer().serializeToString(
    $ssmlQuery.children().eq(0)[0]
  )

  return strippedSSML
}

/**
 * Split sentences in the full caption text string
 * @param captionText The full text of the digital human response
 *
 * @todo Add tests for this method and move into utility class
 */
const splitSentences = (captionText: string) => {
  let { displaySentences, countSentences, textToDisplay } = splitByBreakSSML(
    captionText
  )

  if (displaySentences.length === 0) {
    const textSentences = textToDisplay.match(regexpressions.sentences)

    if (textSentences) {
      displaySentences = textSentences.map((sentence: string) => {
        return {
          pauseDuration: 0,
          text: sentence.trim()
        }
      })
    } else {
      // No punctuation breaks or SSML found, return full text
      displaySentences.push({
        pauseDuration: 0,
        text: textToDisplay
      })
    }

    countSentences = displaySentences
  }

  return { displaySentences, countSentences }
}

/**
 *
 * @param captionList The caption data to iterate through
 * @param currentIndex The index of the currently displayed caption sentence
 * @param currentTime The current time elapsed in seconds since component was instantiated
 * @param startTime The unix timestamp when the component was instantiated
 * @returns string Next caption text to display
 *
 * @todo Add tests for this method and move into utility class
 */
const getNextCaptionSentence = (
  captionList: Caption[],
  currentTime: number
) => {
  const captionsToDisplay = captionList.filter(caption => {
    return caption.endTime > currentTime
  })

  const nextCaption = captionsToDisplay[0]
  return nextCaption ? nextCaption.text : ''
}

/**
 * Splits a string by the break SSML tag
 * @param text Input text to split
 *
 * @todo Add tests for this method and move into utility class
 * @todo Create interface for return type
 */
const splitByBreakSSML = (text: string) => {
  const strippedSSML = stripSSMLTags(text, ssmlTagsToRemove)
    .replace(regexpressions.speakTagsToRemove, '')
    .trim()

  //const stripped = text.replace(regexpressions.ssmlTagsToRemove, '').trim()
  // Transform <sub> SSML tags to use their alias attribute value in place of tag
  const textToCount = transformSubSSML(`<speak>${strippedSSML}</speak>`, true)

  // Strip <sub> SSML tags from display text
  const textToDisplay = transformSubSSML(`<speak>${strippedSSML}</speak>`)

  const parser = new DOMParser()

  const displaySSML = parser.parseFromString(textToDisplay, 'text/xml')
  if (displaySSML.getElementsByTagName('parsererror').length) {
    console.warn(displaySSML)
    console.info(text)
  }

  const countSSML = parser.parseFromString(textToCount, 'text/xml')
  if (countSSML.getElementsByTagName('parsererror').length) {
    console.warn(countSSML)
    console.info(text)
  }

  const displaySentences = getSSMLSentences(displaySSML)
  const countSentences = getSSMLSentences(countSSML)

  return {
    displaySentences,
    countSentences,
    textToDisplay: textToDisplay.replace(regexpressions.speakTagsToRemove, '')
  }
}

/**
 * Get SSML Sentences from an input XML document
 *
 * @param ssml Extracts sentences from this SSML XMLDocument
 * @returns Array of objects containing the caption text and additional break time the caption should be available on screen
 *
 * @todo Add tests for this method and move into utility class
 * @todo Create interface for return type
 */
const getSSMLSentences = (ssml: XMLDocument) => {
  const breaks = ssml?.getElementsByTagName('break')
  const sentences: Sentence[] = []

  if (breaks.length === 0) {
    return sentences
  }

  for (let i = 0; i < breaks.length; i++) {
    const timeAttr = breaks[i].getAttribute('time')
    const msMatches = timeAttr?.match(regexpressions.breakMilliseconds)
    const secMatches = timeAttr?.match(regexpressions.breakSeconds)
    let duration = 0

    if (msMatches) {
      duration += Number(msMatches[1])
    } else if (secMatches) {
      duration += Number(secMatches[1])
    } else if (timeAttr) {
      duration += getSSMLBreakStrength(timeAttr.toLowerCase())
    }

    const sentenceText = breaks[i].previousSibling?.nodeValue

    if (sentenceText) {
      sentences.push({
        pauseDuration: duration,
        text: sentenceText.trim()
      })
    }
  }

  // Test if last break tag has trailing text, if so add it as an extra sentence.
  const trailingTextNode = breaks[breaks.length - 1].nextSibling
  if (trailingTextNode?.nodeValue) {
    sentences.push({
      pauseDuration: 0,
      text: trailingTextNode.nodeValue.trim()
    })
  }

  return sentences
}

/**
 * Gets the <break> strength in milliseconds for an SSML time text value
 * @param strength Google SSML compatible break value
 * @return Number of milliseconds for break duration
 *
 * @todo Add tests for this method and move into utility class
 */
const getSSMLBreakStrength = (strength: string) => {
  switch (strength) {
    case 'x-weak':
      return 100
    case 'weak':
      return 250
    case 'medium':
      return 500
    case 'strong':
      return 1000
    case 'x-strong':
      return 2000
    default:
      return 0
  }
}

/**
 * Transform SSML string containing <sub> tags
 *
 * Any <sub> tags present are replaced with their text value inside the alias property
 *
 * @todo Add tests for this method and move into utility class
 *
 * @param text Input SSML string
 * @param useAlias Whether or not to replace <sub> tag with alias value. Defaults to replacing using textContent value of tag element.
 */
const transformSubSSML = (text: string, useAlias?: boolean) => {
  const parser = new DOMParser()

  const ssml = parser.parseFromString(text, 'text/xml')
  if (ssml.getElementsByTagName('parsererror').length) {
    console.warn(ssml)
    console.info(text)
    return text
  }

  for (let i = 0; i < ssml.documentElement.childNodes.length; i++) {
    const childNode = ssml.documentElement.childNodes[i]

    if (childNode.nodeName === 'sub') {
      const element = childNode as Element
      const text = useAlias
        ? element.getAttribute('alias')
        : element.textContent
      if (text) {
        ssml.documentElement.replaceChild(ssml.createTextNode(text), childNode)
      }
    }
  }

  return new XMLSerializer().serializeToString(ssml)
}

/**
 * Captions component
 *
 * @caption Input text for caption - the full text transcript of the digital human response (with or without SSML tags)
 *
 * @todo Move caption pre-processing logic into its own utils class and let Captions component handle display logic such as timing on-screen
 */
const Captions: React.FC<CaptionsProps> = React.forwardRef(
  ({ captions }, ref): any => {
    if (!captions) return <></>

    const [captionList, setCaptionList] = useState<Caption[]>([])
    const [currentSentence, setCurrentSentence] = useState('')
    const [isActive, setIsActive] = useState(false)

    function startTimer() {
      setIsActive(true)
    }

    function stopTimer() {
      setIsActive(false)
    }

    function restartTimer() {
      stopTimer()
      startTimer()
    }

    useEffect(() => {
      let interval: number | undefined = undefined
      if (isActive) {
        interval = setInterval(() => {
          setCurrentSentence(getNextCaptionSentence(captionList, Date.now()))
        }, timerInterval)
      } else if (!isActive) {
        clearInterval(interval)

        setCurrentSentence('')
      }
      return () => {
        clearInterval(interval)
        setCurrentSentence('')
      }
    }, [isActive, captionList])

    const initCaptions = (captions: string, timerStartTime: number) => {
      const { displaySentences, countSentences } = splitSentences(captions)

      let lastCaption: Caption
      let newCaptionData: Caption[] = []
      displaySentences.forEach((sentence: Sentence, index) => {
        const words =
          countSentences[index].text.match(regexpressions.words) || []
        const wordCount = words ? words.length : 0
        const wordsPerSecond = speakingRate / 60
        const captionDuration = Math.ceil((wordCount / wordsPerSecond) * 1000)
        const pauseDuration = sentence.pauseDuration

        const baseStartTime = lastCaption?.endTime || timerStartTime

        const newCaption: Caption = {
          text: sentence.text,
          words,
          // Set endTime to a very large value so last Caption stays visible on screen until this component unmounts
          endTime:
            index === displaySentences.length - 1
              ? 999999999999999
              : baseStartTime + captionDuration + pauseDuration,
          captionDuration,
          pauseDuration
        }

        lastCaption = newCaption

        newCaptionData.push(newCaption)
      })

      setCaptionList(newCaptionData)
    }

    useEffect(() => {
      // componentDidMount
      initCaptions(captions, Date.now())
      // start timer when captions are shown
      startTimer()

      return () => {
        // componentWillUnmount
        // clean up timer before captions are hidden
        stopTimer()
      }
    }, [])

    useEffect(() => {
      // componentDidUpdate
      restartTimer()
      initCaptions(captions, Date.now())
    }, [captions])

    return (
      <Flex sx={styles.container}>
        <Flex sx={styles.inner} ref={ref}>
          <Text as="span">{currentSentence}</Text>
        </Flex>
      </Flex>
    )
  }
)

export default Captions
