import React, { useContext, useRef } from 'react'
import { Text, Button } from 'rebass'
import { trackHandler, UneeqContext, useUneeqState } from 'uneeq-react-core'
import RestoreIcon from '@material-ui/icons/Restore'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import styles from './styles'
import { useTranslation } from 'react-i18next'
import { debounce } from 'lodash'

const RestartConversationButton = () => {
  const { sendText, config } = useContext(UneeqContext)
  const { t } = useTranslation()

  // Debounce sending text endConversation utterance to once per 2 seconds
  const debouncedSend = useRef(debounce((text: string) => sendText(text), 2000))
    .current

  const restartConversation = () => {
    debouncedSend(config.restartConversationUtterance)
  }

  return (
    <Button
      sx={styles.button}
      variant="primaryInverted"
      onClick={trackHandler(restartConversation, 'restart-conversation-btn')}
    >
      <ArrowBackIcon />
      <Text as="span" sx={styles.text}>
        {t('restartConversationButton.label')}
      </Text>
    </Button>
  )
}

export default RestartConversationButton
