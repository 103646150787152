import React, { useState, useRef, KeyboardEvent, useContext } from 'react'
import IconButton from '@material-ui/core/IconButton'
import { ThemeProvider, createMuiTheme } from '@material-ui/core/styles'
import { Flex } from 'rebass'
import styles from './styles'
import {
  trackEvent,
  trackHandler,
  UneeqContext,
  useUneeqState
} from 'uneeq-react-core'
import { Label, Input } from '@rebass/forms'
import SendIcon from '@material-ui/icons/Send'
import { useTranslation } from 'react-i18next'
import { debounce } from 'lodash'

const muiTheme = createMuiTheme({
  palette: {
    type: 'dark',
    primary: {
      main: '#ffffff'
    },
    secondary: {
      main: '#ffffff'
    },
    contrastThreshold: 3,
    tonalOffset: 0.2
  }
})

const Chat = () => {
  const [text, setText] = useState('')
  const { sendText, dispatch } = useContext(UneeqContext)
  const { typeModeFromBackend, recording } = useUneeqState()
  const { t } = useTranslation()

  // Debounce sending text chat messages to once per 2 seconds
  const debouncedSend = useRef(debounce((text: string) => sendText(text), 2000))
    .current

  const sendOnEnter = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === ' ' && text === '') {
      event.preventDefault()
    } else if (text.trim().length > 0 && event.key === 'Enter') {
      trackEvent('chat-send-enter-key')
      setText('')
      debouncedSend(text)
      dispatch({ type: 'setThinking', payload: true })
    }
  }

  return (
    <Flex sx={styles.chatContainer}>
      <Label sx={styles.chatLabel} htmlFor="chat">
        Chat
      </Label>
      <Input
        id="chat"
        value={text}
        placeholder={
          recording ? "I'm listening..." : t('BottomBar.Chat.placeholder')
        }
        onChange={e => setText(e.target.value)}
        onKeyDown={sendOnEnter}
        sx={styles.chatTextInput}
      />
      {text !== '' && (
        <ThemeProvider theme={muiTheme}>
          <IconButton
            aria-label="send"
            onClick={trackHandler(() => {
              setText('')
              debouncedSend(text)
            }, 'chat-send-btn')}
          >
            <SendIcon />
          </IconButton>
        </ThemeProvider>
      )}
    </Flex>
  )
}

export default Chat
