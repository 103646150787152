export const styles = {
  button: {
    position: 'absolute',
    top: [64, 64, 64, 64, 24, 24],
    right: [4, 4, 4, 13, 210, 210],
    width: [28, 28, 40, 40, 'auto', 'auto'],
    height: [28, 28, 40, 40, 'auto', 'auto'],
    '& > svg': {
      mr: [0, 0, 0, 0, '8px', '8px'],
      minWidth: ['28px', '28px', '28px', '28px', '28px', '28px'],
      minHeight: ['28px', '28px', '28px', '28px', '28px', '28px']
    },
    borderRadius: [40, 40, 40, 40, 4, 4],
    py: [0, 0, 0, 0, 4, 4],
    pr: 6,
    background: 'none',
    borderWidth: 0
  },
  text: {
    display: ['none', 'none', 'none', 'none', 'inline-block', 'inline-block']
  }
}
export default styles
