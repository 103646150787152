import React, { createElement, useRef, useContext, useState } from 'react'
// import ReactHtmlParser from 'react-html-parser'
import { Image, Link, Text, Heading, Flex } from 'rebass'
import { Button } from 'rebass'
import { UneeqContext } from 'uneeq-react-core'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import Radio from '@material-ui/core/Radio'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import {
  ThemeProvider,
  createMuiTheme,
  makeStyles
} from '@material-ui/core/styles'

import { debounce } from 'lodash'
// @ts-ignore
import marksy from 'marksy'
import { styles as s } from './styles'
const styles = s.informationContent
import UtteranceMenuButton from '../UtteranceMenuButton/UtteranceMenuButton'
import FormControl from '@material-ui/core/FormControl'

const muiTheme = createMuiTheme({
  palette: {
    type: 'dark',
    primary: {
      main: '#ffffff'
    },
    secondary: {
      main: '#ffffff'
    },
    contrastThreshold: 3,
    tonalOffset: 0.2
  },
  typography: {
    fontFamily: "'Varela Round', system-ui, sans-serif"
  }
})

export const getUtteranceFromURI = (uri: string) => {
  const match = uri.match(/^say\:(.*)$/)
  if (!match) {
    return undefined
  }
  return match[1]
}

const compile = (
  markdown: string,
  send: (text: string) => void,
  sendCallback: (text: string) => void
) => {
  const parser = marksy({
    components: {
      /*
        Use the following format in DialogFlow or other NLP service to add a button that sends an utterance when clicked to the UneeQ platform:

        {
          "instructions": {
            "displayHtml": {
              "html": "{\"information\":\"# Choose an option: \\n\\n ```marksy\\nh(UtteranceButton, {className: \\\"back-btn\\\", utterance: \\\"no\\\"}, \\\"Back to Conversation\\\")\\n```\"}"
            }
          }
        }
      */
      RadioUtteranceButton(props: any) {
        const [checked, setChecked] = useState(false)
        return (
          <Flex sx={styles.formControlContainer}>
            <FormControl style={styles.formControl}>
              <FormControlLabel
                style={styles.formControlLabel}
                value={props.utterance}
                control={
                  <Radio
                    checked={checked}
                    onChange={event => {
                      setChecked(true)
                      event.preventDefault()
                      send(props.utterance)
                      sendCallback(props.utterance)
                    }}
                  />
                }
                label={props.children}
              />
            </FormControl>
          </Flex>
        )
      },
      UtteranceButton(props: any) {
        return (
          <Button
            sx={styles.utteranceButton}
            variant={props.variant}
            rel="noopener noreferrer"
            onClick={event => {
              event.preventDefault()
              send(props.utterance)
              sendCallback(props.utterance)
            }}
          >
            {props.icon === 'arrow-back' && <ArrowBackIcon />} {props.children}
          </Button>
        )
      }
    },
    elements: {
      a: (props: any) => {
        const utterance = getUtteranceFromURI(props.href)
        /*
        href
        title
        target
        children
        */
        return utterance ? (
          <a
            {...props}
            rel="noopener noreferrer"
            onClick={(event: Event) => {
              event.preventDefault()
              send(utterance)
              sendCallback(utterance)
            }}
          />
        ) : (
          <a {...props} rel="noopener noreferrer" target="_blank" />
        )
      },
      h1: ({ children }: any) => <Heading sx={s.headings}>{children}</Heading>,
      h2: ({ children }: any) => <Heading sx={s.headings}>{children}</Heading>,
      h3: ({ children }: any) => <Heading sx={s.headings}>{children}</Heading>,
      h4: ({ children }: any) => <Heading sx={s.headings}>{children}</Heading>
    },
    createElement
  })

  return parser(markdown, {})
}

export interface HeadingInformation {
  type: 'heading'
  text: string
}
export interface TextInformation {
  type: 'text'
  text: string
}
export interface HTMLInformation {
  type: 'html'
  html: string
}
export interface MarkdownInformation {
  type: 'markdown'
  markdown: string
}
export interface ImageInformation {
  type: 'image'
  source: string
  label: string
  width: string
}
interface VideoInformation {
  type: 'video'
  source: string
  width: string
  height: string
}
export interface LinkInformation {
  type: 'link'
  href: string
  label: string
}

export type InformationItemWithoutList =
  | HeadingInformation
  | TextInformation
  | HTMLInformation
  | ImageInformation
  | VideoInformation
  | LinkInformation
  | MarkdownInformation

export interface ListInformation {
  type: 'list'
  items: InformationItemWithoutList[]
}

export interface UtteranceListItem {
  label: string
  utterance: string
}

export interface UtteranceListInformation {
  type: 'utteranceList'
  label: string
  items: UtteranceListItem[]
}

export type InformationItem =
  | ListInformation
  | HeadingInformation
  | TextInformation
  | HTMLInformation
  | ImageInformation
  | VideoInformation
  | LinkInformation
  | UtteranceListInformation
  | MarkdownInformation

const renderInformationItem = (item: InformationItem, index: number): any => {
  switch (item.type) {
    case 'html':
      console.warn('HTML type used')
      return null //ReactHtmlParser(item.html)
    case 'text':
      return <Text key={index}>{item.text}</Text>
    case 'heading':
      return (
        <Text fontSize={3} key={index}>
          {item.text}
        </Text>
      )
    case 'markdown':
      const { sendText, dispatch } = useContext(UneeqContext)
      const debouncedSend = useRef(
        debounce((text: string) => sendText(text), 2000)
      ).current
      const compiled = compile(item.markdown, debouncedSend, (text: string) => {
        dispatch({ type: 'suggestedResponseSent', payload: text })
      })
      return compiled.tree
    case 'list':
      return (
        <ul key={index}>
          {item.items.map((child, index) =>
            renderInformationItem(child, index)
          )}
        </ul>
      )
    case 'link':
      return (
        <Link href={item.href} rel="external" target="_blank" key={index}>
          <Text>{item.label}</Text>
        </Link>
      )
    case 'utteranceList':
      return (
        <UtteranceMenuButton
          label={item.label}
          items={item.items}
          key={index}
        />
      )
    case 'image':
      return <Image src={item.source} alt={item.label} key={index} />
    case 'video':
      return (
        <iframe
          src={item.source}
          width={item.width || '100%'}
          height={item.height || '375'}
          key={index}
          frameBorder={0}
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        ></iframe>
      )
  }
}

export interface InformationProps {
  information: InformationItem[]
}

const InformationContent: React.FC<InformationProps> = ({ information }) => {
  if (!information?.length) return null

  return (
    <>
      <ThemeProvider theme={muiTheme}>
        {information.map((item: InformationItem, index: number) => {
          return renderInformationItem(item, index)
        })}
      </ThemeProvider>
    </>
  )
}

export default InformationContent
