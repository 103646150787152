const styles = {
  container: {
    display: ['flex', 'flex', 'flex'],
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    transition: 'all 1s'
  },
  inner: {
    fontWeight: '800',
    fontSize: '18px',
    textAlign: 'center',
    backgroundColor: 'rgba(0, 0, 0, 0.55)',
    color: 'textAlternate',
    lineHeight: '20px',
    mb: 6,
    minHeight: [44, 44, 44, 44, 44, 44],
    maxHeight: [184, 184, 144, 144, 144, 144],
    minWidth: 144,
    maxWidth: ['90%', '90%', '90%', '90%', 634, 634],
    overflow: 'ellipsis',
    bottom: 85,
    py: [4, 4, 4, 4, 4, 4],
    px: [6, 6, 6, 6, 6, 6],
    alignItems: 'center',
    justifyContent: 'center',
    width: 'auto',
    '& span': {
      minWidth: 'auto'
    }
  }
}

export default styles
