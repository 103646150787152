import { keyframes } from '@emotion/core'

const heartFade = keyframes`
    0% {
        opacity: 1;
        transform: translateY(1px) scale(1);
        -webkit-transform: translateY(1px) scale(1);
    }
    100% {
        opacity: 0;
        transform: translateY(-50px) scale(2);
        -webkit-transform: translateY(-50px) scale(2);
    }
`

export const styles = {
  conatianer: {
    label: 'onscreen-info',
    transition: 'height 0.5s',
    position: ['relative', 'relative', 'absolute'],
    height: ['100%', '100%', 'auto'],
    right: [0, 0, 0, 0, 80, 80],
    maxWidth: ['100%', '100%', '100%', '100%', 560, 560],
    top: ['auto', 'auto', 'auto', 'auto', 88, 88],
    bottom: ['auto', 'auto', 'auto', 'auto', 195, 195],
    width: '100%',
    display: ['none', 'none', 'none', 'none', 'flex', 'flex'],
    flexDirection: 'column',
    justifyContent: ['flex-start', 'flex-start', 'center'],
    left: [0, 0, 0, 0, 'auto', 'auto'],
    zIndex: [4, 4, 4, 4, 1, 1]
  },
  card: {
    label: 'info-card',
    color: 'textAlternate',
    display: 'flex',
    flexDirection: 'column',
    minHeight: ['100%', '100%', 250],
    background: ['#000000', '#000000', 'none'],
    boxShadow: 'none',
    fontSize: [0, 1, 3],

    h2: {
      fontSize: [1, 1, 5]
    },

    p: {
      fontSize: [0, 1, 3]
    }
  },
  information: {
    content: {
      label: 'information-content',
      position: 'relative',
      minHeight: 0,
      li: {
        fontSize: 1
      },
      '& a:link': {
        color: 'primary'
      },
      '& a:visited': {
        color: 'primary'
      },
      '& a:hover': {
        color: 'primaryLight'
      },

      // Needed to make sure safari keeps 'information-scroll' from overflowing this
      //display: 'flex',
      flexDirection: 'column'
    },
    scroll: {
      flexDirection: 'column',
      alignItems: ['inherit', 'inherit', 'flex-start'],
      label: 'information-scroll',
      overflowY: ['scroll', 'scroll', 'auto'],
      overflowX: 'hidden',
      minHeight: [120, 120, 120]
      //height: [275, 300, '100%'],
      //maxHeight: [275, 300, '100%']
    },
    closeButtonContainer: {
      display: ['flex', 'flex', 'flex', 'flex', 'none', 'none'],
      justifyContent: 'flex-end'
    },
    closeButton: {
      p: 0,
      mt: [0, 0, 0, 0, '-10px', '-10px'],
      mr: [0, 0, 0, 0, '-10px', '-10px'],
      zIndex: 2
    },
    topFade: {
      label: 'topFade',
      height: 25,
      left: 0,
      right: 0,
      position: 'absolute',
      top: 0,
      zIndex: 1,
      WebkitMaskImage:
        'linear-gradient(to bottom, rgba(0, 0, 0, 1) 50%,rgba(0, 0, 0, 0) 100%);',
      maskImage:
        'linear-gradient(to bottom, rgba(0, 0, 0, 1) 50%,rgba(0, 0, 0, 0) 100%);'
    },
    bottomFade: {
      label: 'bottomFade',
      bottom: 0,
      height: 25,
      left: 0,
      right: 0,
      position: 'absolute',
      WebkitMaskImage:
        'linear-gradient(to top, rgba(0, 0, 0, 1) 50%,rgba(0, 0, 0, 0) 100%);',
      maskImage:
        'linear-gradient(to top, rgba(0, 0, 0, 1) 50%,rgba(0, 0, 0, 0) 100%);'
    },
    buttonsContainer: {
      mt: [0, 0, '10px', '10px', 11, 11],
      justifyContent: 'flex-end',
      color: 'primary',
      '& svg': {
        zIndex: 1
      }
    },
    expandButton: {
      alignItems: 'center',
      display: ['none', 'none', 'none', 'none', 'flex', 'flex'],
      '& svg': {
        mr: '5px',
        mt: ['3px', '3px', '3px', '3px', 0, 0]
      }
    },
    saveButton: {
      alignItems: 'center',
      display: 'flex',
      '& svg': {
        mr: '2px'
      }
    },
    buttonText: {
      fontSize: 1,
      display: ['none', 'none', 'none', 'block']
    }
  },
  savedItems: {
    heartContainer: {
      animation: `${heartFade} 1.5s ease forwards`,
      zIndex: 1,
      position: 'absolute'
    },
    savedItemsButton: {
      display: ['none', 'none', 'none', 'none', 'flex', 'flex'],
      cursor: 'pointer',
      zIndex: 4,
      '& button': {
        alignItems: 'center',
        display: 'flex',
        color: 'primary',
        fontSize: 2
      },
      '& button svg': {
        mr: 1
      }
    },
    mobileSavedItemsButton: {
      display: ['flex', 'flex', 'flex', 'flex', 'none', 'none'],
      cursor: 'pointer',
      zIndex: 4,
      color: 'primary',
      width: [30, 30, 40, 40, 40, 40],
      height: [30, 30, 40, 40, 40, 40],
      p: 0,
      borderRadius: 1000,
      '& svg': {
        mr: 0,
        minWidth: 15
      }
    }
  },
  informationContent: {
    utteranceButton: {
      my: 4
    },
    formControlContainer: {
      label: 'form-control-container',
      my: [0, 1, 4],
      overflow: 'visible',
      '&:hover': {
        backgroundColor: [
          'rgba(255, 255, 255, 0.25)',
          'rgba(255, 255, 255, 0.25)',
          'rgba(0, 0, 0, 0.25)'
        ]
      },

      pl: 2
    },
    formControl: {
      display: 'flex',
      flex: 1
    },
    formControlLabel: {
      display: 'flex',
      flex: 1
    }
  },
  headings: {
    fontSize: 2,
    fontWeight: 'bold',
    m: 0,
    p: 0
  },
  informationExpanded: {
    container: {
      label: 'info-expanded-container',
      position: 'fixed',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      zIndex: 3,
      backdropFilter: 'blur(4px)',
      webkitBackdropFilter: 'blur(4px)',
      backgroundColor: 'rgba(0,0,0,0.7)',
      alignItems: 'center',
      justifyContent: 'center',
      overflow: 'hidden',
      'h1,h2,h3,h4': {
        fontSize: '18px'
      },
      content: {
        '& a:link': {
          color: 'inherit'
        },
        '& a:visited': {
          color: 'inherit'
        },
        '& a:hover': {
          color: 'inherit'
        }
      }
    },
    closeButtonContainer: {
      justifyContent: 'flex-end'
    },
    closeButton: {
      p: 0,
      mt: [0, 0, 0, 0, '-10px', '-10px'],
      mr: [0, 0, 0, 0, '-10px', '-10px']
    },
    contentAndButton: {
      width: ['95%', '95%', '95%', '95%', 850, 850],
      maxHeight: ['95%', '95%', '95%', '95%', 552, 552],
      p: 12,
      flexDirection: 'column'
    },
    scrollContainer: {
      label: 'scroll-containter',

      whiteSpace: 'pre-wrap',
      msOverflowStyle: 'none',
      scrollbarWidth: 'none',
      maxHeight: [350, 350, 350, 350, 550, 550],
      '& a:visited': {
        color: 'primary'
      }
    },
    saveButton: {
      width: 82,
      alignItems: 'center',
      display: 'flex',
      '& svg': {
        mr: '2px'
      }
    },
    trashButton: {
      alignItems: 'center',
      display: 'flex',
      '& svg': {
        mr: '5px'
      }
    },
    minimizeButton: {
      alignItems: 'center',
      display: ['none', 'none', 'none', 'none', 'flex', 'flex'],
      '& svg': {
        mr: '5px'
      }
    },
    downloadButton: {
      alignItems: 'center',
      display: 'flex',
      '& svg': {
        mr: '5px'
      }
    },
    buttonsContainer: {
      mt: [5, 5, 5, 5, 11, 11],
      mb: [5, 5, 5, 5, 0, 0],
      justifyContent: 'flex-end',
      '& button': {
        pb: [6, 6, 6, 6, 2, 2]
      },
      '& svg': {
        zIndex: 1
      }
    },
    paginationContainer: {
      borderTop: '1px solid transparent',
      borderTopColor: 'grey',
      pt: 7,
      px: 14,
      justifyContent: 'space-between',
      alignItems: 'center'
    }
  },
  suggestedResponses: {
    verticalContainer: {
      position: [undefined, undefined, 'fixed'],
      left: [undefined, undefined, '22px'],
      transform: [undefined, undefined, 'translateY(-50%)'],
      height: [undefined, undefined, 'auto'],
      width: [undefined, undefined, 'auto']
    },
    title: {
      color: 'textAlternate',
      label: 'title',
      mb: 6,
      fontWeight: 'bold'
    },
    itemsContainer: {
      label: 'suggestedResponses-itemsContainer',
      width: '100%',
      overflowX: 'auto',
      maxWidth: '100%',
      justifyContent: [
        'flex-start',
        'flex-start',
        'center',
        'center',
        'center',
        'center'
      ]
    },
    item: {
      label: 'suggestedResponses-item',
      alignItems: 'center',
      justifyContent: 'space-between',
      cursor: 'pointer',
      position: 'relative',
      mx: ['2px', '2px', '2px', '2px', 3, 3],
      textAlign: 'center',
      display: 'inline-flex',
      minWidth: 'auto',
      whiteSpace: 'nowrap'
    }
  }
}
export default styles
