import ReactGA from 'react-ga'
import downloadPdf from './app/downloadPdf'
import Loader from './app/Loader'

interface Env {
  conversationId?: string,
  tokenUrl?: string,
  usePasscode?: boolean,
  showPasscodeDefault?: boolean,
  orchestrationToken?: any,
  analyticsID?: string,
  analyticsDebug?: boolean,
}

let environment: Env = {} 
// You can override default settings for different environments
// by setting REACT_APP_CONFIG environment variable
if (process.env.REACT_APP_CONFIG === 'DEVELOPMENT') {
  console.info('config: development')
  environment = {
    conversationId: '09a3b12d-8097-43fe-a026-ebbe5b106b68',
    tokenUrl:
      'https://5mzwrlt2a6.execute-api.us-east-2.amazonaws.com/staging/token'
  }
} else if (process.env.REACT_APP_CONFIG === 'STAGING') {
  console.info('config: staging')
  environment = {
    conversationId: '09a3b12d-8097-43fe-a026-ebbe5b106b68',
    tokenUrl:
      'https://5mzwrlt2a6.execute-api.us-east-2.amazonaws.com/staging/token'
  }
} else if (process.env.REACT_APP_CONFIG === 'PRODUCTION') {
  console.info('config: production')
  environment = {
    conversationId: '09a3b12d-8097-43fe-a026-ebbe5b106b68',
    tokenUrl:
      'https://5mzwrlt2a6.execute-api.us-east-2.amazonaws.com/production/token',
    usePasscode: true,
    showPasscodeDefault: true,
    orchestrationToken: process.env.REACT_APP_UNEEQ_ORCHESTRATION_HEADER,
    analyticsID: 'UA-112913554-7',
    analyticsDebug: false
  }
} else {
  console.info('config: default')
}

interface UneeQLanguages {
  name: string,
  code: string,
  privacyPolicyURL: string,
  introVideo: string,
  conversationId: string
}

let languages: Array<UneeQLanguages> = [
  {
    name: 'English',
    code: 'en-US',
    privacyPolicyURL: '/locales/en-US/privacy-policy.html',
    introVideo: '/videos/intro-en-US.mp4',
    conversationId: '09a3b12d-8097-43fe-a026-ebbe5b106b68'
  },
  {
    name: 'Spanish',
    code: 'es-ES',
    privacyPolicyURL: '/locales/es-ES/privacy-policy.html',
    introVideo: '/videos/intro-es-ES.mp4',
    conversationId: 'd05b7d9c-10b5-4924-a368-8bc01dd6aeaa'
  },
  {
    name: 'Portuguese',
    code: 'pt-BR',
    privacyPolicyURL: '/locales/pt-BR/privacy-policy.html',
    introVideo: '/videos/intro-pt-BR.mp4',
    conversationId: 'a4da3e23-1967-427b-b1cb-a0f7f710869a'
  },
  {
    name: 'French',
    code: 'fr-FR',
    privacyPolicyURL: '/locales/fr-FR/privacy-policy.html',
    introVideo: '/videos/intro-fr-FR.mp4',
    conversationId: '1189235d-a325-4175-87f0-674553722854'
  },
  {
    name: 'Dutch',
    code: 'nl-NL',
    privacyPolicyURL: '/locales/nl-NL/privacy-policy.html',
    introVideo: '/videos/intro-nl-NL-v2.mp4',
    conversationId: 'edadfbef-a637-49c6-bf5c-9b0504222f69'
  },
  {
    name: 'Japanese',
    code: 'ja-JP',
    privacyPolicyURL: '/locales/ja-JP/privacy-policy.html',
    introVideo: '/videos/intro-ja-JP.mp4',
    conversationId: '3d4edf2e-2281-4fa8-86d0-f94576e05bc9'
  },
  {
    name: 'Korean',
    code: 'ko-KR',
    privacyPolicyURL: '/locales/ko-KR/privacy-policy.html',
    introVideo: '/videos/intro-ko-KR-v2.mp4',
    conversationId: '6add49d5-2173-4d1f-93cb-600d27b6904c'
  },
  {
    name: 'Romanian',
    code: 'ro-RO',
    privacyPolicyURL: '/locales/ro-RO/privacy-policy.html',
    introVideo: '/videos/intro-ro-RO-v2.mp4',
    conversationId: '90f29ce9-8c1e-4be0-bf83-5c2f0ae86317'
  }
]

interface UneeQConfig {
  debug: boolean,
  url: string,
  conversationId: string,
  tokenUrl: string,
  usePasscode: boolean,
  recaptchaSiteKey: string,
  orchestrationToken?: string,
  showPasscodeDefault: boolean,
  analyticsID?: any, // void | undefined not assignable to string, though this is a string when set
  analyticsDebug?: boolean,
  playWelcome: boolean,
  sendLocalAudio: boolean,
  useLocalAudio: boolean,
  sendLocalVideo: boolean,
  customData: any,
  tapThreshold: number,
  timeout: number,
  timeoutWarning: number,
  avatarThinkingLoader: any,
  avatarAspect: number,
  avatarPosition: number,
  informationInTranscript: boolean,
  restartConversationUtterance: string,
  errorLevels: any,
  emptyTranscriptThreshold: number,
  avatarName: string,
  default_language: string,
  showEscalationForm: boolean,
  analytics: any,
  downloadPdf: any,
  languages: Array<UneeQLanguages>
}

const config: UneeQConfig = {
  debug: true,

  url: process.env.REACT_APP_UNEEQ_URL!,
  conversationId: process.env.REACT_APP_UNEEQ_PERSONA_ID!,
  tokenUrl: process.env.REACT_APP_UNEEQ_TOKEN_URL!,

  // Is a passcode needed to obtain a token? Show a passcode screen so the user can provide it.
  usePasscode: false,
  // Recaptcha - if used with passcode
  recaptchaSiteKey: process.env.REACT_APP_RECAPTCHA_SITE_KEY!,
  
  showPasscodeDefault: false,
  analyticsID: '',
  analyticsDebug: true,

  // welcome can be suppressed during dev with REACT_APP_NO_WELCOME=1
  playWelcome: !process.env.REACT_APP_NO_WELCOME,
  sendLocalAudio: true,
  useLocalAudio: true,
  sendLocalVideo: false,
  customData: {},

  // When holding down spacebar, durations shorter that this will trigger
  // a message like "Keep holding spacebar while you talk"
  tapThreshold: 700, // ms

  // How long can the user be inactive before timeout
  timeout: 480 * 1000, // ms
  // How close to the end of the timeout should we show the warning
  timeoutWarning: 180 * 1000, // ms
  // e.g. timeout=90sec, timeoutWarning=30sec - after 60 secs of inactivity warning will show, after 90 secs sessions ends

  // Loading animation when DH is thinking. See the available loaders here https://github.com/davidhu2000/react-spinners
  avatarThinkingLoader: Loader,

  // If the avatar is not centred within the video avatarPosition needs to be changed.
  // This will ensure correct positioning narrow (mobile) screens
  avatarAspect: 16 / 9, // video aspect ratio
  avatarPosition: 0.34, // Where is the avatar in the video frame (0.5 = center, 0.33 = 1/3 from the left)

  informationInTranscript: false,

  restartConversationUtterance: 'Can we start again?',

  // show we show the contact form after Feedback?
  showEscalationForm: false,

  analytics: (label: string, action?: string) => {
    if (config.analyticsID) {
      ReactGA.event({
        category: 'Application',
        action: action || 'click',
        label
      })
    }
  },

  errorLevels: {
    ignore: [
      11001,
      11002,
      14001,
      14002,
      14003,
      15001,
      12002,
      12003,
      10003,
      10004,
      10005,
      6016,
      6013,
      4200,
      4201,
      4202,
      4203,
      4204,
      4205
    ],
    warning: ['AvatarAvailable', 'ConnectionLost', 'Warning', 4206, 4207],
    error: [
      'AvatarUnavailable',
      'MicActivityError',
      'DeviceNotFoundError',
      'DeviceError',
      2001,
      2002,
      3001,
      3002,
      3003,
      3004,
      7006,
      7007,
      10001,
      10002,
      11004,
      11005,
      12001
    ],
    fatal: [
      'TokenError',
      'ServiceUnavailable',
      'ErrorEndingSession',
      1001,
      1002,
      1003,
      1004,
      1005,
      1006,
      1007,
      1008,
      1009,
      1010,
      1011,
      1012,
      1013,
      1014,
      1015,
      1016,
      1017,
      1018,
      1019,
      1020,
      1021,
      1022,
      1023,
      1024,
      1025,
      4001,
      4002,
      4003,
      4004,
      4005,
      4006,
      4007,
      4008,
      4009,
      5001,
      5002,
      5003,
      5004,
      5005,
      5006,
      5007,
      5008,
      5009,
      5010,
      5011,
      5012,
      5013,
      5014,
      5015,
      5016,
      5017,
      5018,
      5019,
      5020,
      5021,
      5022,
      6001,
      6002,
      6003,
      6004,
      6005,
      6006,
      6007,
      6008,
      6009,
      6010,
      6011,
      6012,
      6014,
      6015,
      7001,
      7002,
      7003,
      7004,
      7005,
      11003,
      13001,
      13002,
      13003,
      13004,
      13005,
      13006
    ]
  },

  // How many empty transcripts before an error is shown
  emptyTranscriptThreshold: 3,
  avatarName: 'Kate',

  downloadPdf,

  ...environment,

  // For multi-lingual environment
  default_language: 'en-US',

  languages: languages
}

export default config
