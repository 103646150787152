import React from 'react'
import { Box } from 'rebass'
import PropagateLoader from 'react-spinners/PropagateLoader'
import RingLoader from 'react-spinners/RingLoader'

const ThinkingLoader: React.FC = () => {
  return (
    <Box sx={{ marginBottom: ['12', '12', '12'] }}>
      <PropagateLoader color="white" size="25px" />
    </Box>
  )
}

export default ThinkingLoader
