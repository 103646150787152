import React from 'react'
import styles from './styles'
import IconButton from '@material-ui/core/IconButton'
import MicIcon from '@material-ui/icons/Mic'
import MicNoneIcon from '@material-ui/icons/MicNone'
import { createMuiTheme } from '@material-ui/core/styles'
import { Box } from 'rebass'

import CircularProgress from '@material-ui/core/CircularProgress'
import { useIsSmallScreen, useUneeqSpaceToTalk } from 'uneeq-react-core'
import { ThemeProvider } from '@material-ui/core/styles'
import Tooltip from '@material-ui/core/Tooltip'
import { useTranslation } from 'react-i18next'

const muiTheme = createMuiTheme({
  palette: {
    type: 'dark',
    primary: {
      main: '#ffffff'
    },
    secondary: {
      main: '#ffffff'
    },
    contrastThreshold: 3,
    tonalOffset: 0.2
  }
})

const PushToTalk: React.FC = () => {
  const isSmallScreen = useIsSmallScreen()
  const {
    sending,
    recording,
    startRecording,
    stopRecording
  } = useUneeqSpaceToTalk()
  const { t } = useTranslation()
  const title = isSmallScreen ? t('BottomBar.PushToTalk.waitingSmallScreen') : t('BottomBar.PushToTalk.waiting')
  return (
    <>
      <ThemeProvider theme={muiTheme}>
        <Tooltip
          title={title}
          aria-label="add"
        >
          <IconButton
            style={{
              ...styles.pttButton,
              borderRadius: recording ? '24px' : '0',
              backgroundColor: recording
                ? 'rgba(255,255,255,0.4)'
                : 'rgba(255,255,255,0)'
            }}
            disableFocusRipple={true}
            disableRipple={true}
            onMouseDown={startRecording}
            onMouseUp={stopRecording}
            onPointerDown={startRecording}
            onPointerUp={stopRecording}
          >
            {sending && !recording ? (
              <CircularProgress style={styles.circularProgress} />
            ) : recording ? (
              <MicIcon style={styles.icons} />
            ) : (
              <MicNoneIcon style={styles.icons} />
            )}
          </IconButton>
        </Tooltip>
      </ThemeProvider>
    </>
  )
}

export default PushToTalk
