import testMP3 from './assets/audio/test.mp3'

const assets = {
  img: {},
  audio: {
    testMP3
  },
  video: {
    permissions: {
      permissionsMP4: '',
      // 'https://einstein.digitalhumans.com/einstein-permissions-720-v2.mp4',
      permissionsWebm: ''
    }
  }
}

export default assets
