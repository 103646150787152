import React from 'react'
import { Flex, SxStyleProp } from 'rebass'

interface OverlayProps {
  children: React.ReactNode
  sx?: SxStyleProp
  transparent?: boolean
  opacity?: Number
}
const Overlay: React.FC<OverlayProps> = ({
  children,
  sx,
  transparent = false,
  opacity = 0.1
}) => {
  return (
    <Flex
      sx={{
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        zIndex: 5,
        backgroundColor: transparent ? 'undefined' : `rgba(0,0,0,${opacity})`,
        alignItems: 'center',
        justifyContent: 'center',
        overflow: 'hidden',
        ...sx
      }}
    >
      {children}
    </Flex>
  )
}

export default Overlay
