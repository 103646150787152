import { useWindowWidth } from '@react-hook/window-size'
import React, { useState } from 'react'
import { Box, Button, Flex, Text, Image, Heading, Link } from 'rebass'
import { trackEvent, trackHandler, useSupportedBrowser, useIsSmallScreen } from 'uneeq-react-core'
import { UnsupportedBrowser } from 'uneeq-react-ui'
import ReactFlagsSelect from 'react-flags-select'
import { Label } from '@rebass/forms'
import { useTranslation } from 'react-i18next'
import Loading from '../Loading'
import togglePrivacyIcon from '../assets/svg/toggle_privacy_icon.svg'
import togglePrivacyIconOpen from '../assets/svg/toggle_privacy_icon_open.svg'
// import HebrewUniversityLogo from '../assets/svg/Hebrew_University_Logo.svg'
// import GreenlightLogo from '../assets/svg/Greenlight_Logo_standard.svg'
// import GoodbyeKansasLogo from '../assets/svg/gbk_logo_studios_neg.svg'
import WolframAlphaLogo from '../assets/svg/wolfram-alpha.svg'
import KateIdlePortraitPoster from '../assets/img/kate-idle-portrait-720.jpg'
import KateIdlePoster from '../assets/img/kate-idle-flipped.jpg'
import KateIntroPoster from '../assets/img/kate-intro-poster.jpg'
import config from '../../config'
import styles from './styles'
import ReactGA from 'react-ga'


const Paragraphs = () => {
  const [showPrivacySummary, setShowPrivacySummary] = useState(false)
  const togglePrivacySummary = () => setShowPrivacySummary(!showPrivacySummary)
  const { t } = useTranslation()

  return (
    <>
      <Text as="p" sx={styles.privacyTextToggle} onClick={togglePrivacySummary}>
        
        {t('Home.byUsingThisService')}
        <Image
          sx={styles.togglePrivacyIcon}
          src={showPrivacySummary ? togglePrivacyIconOpen : togglePrivacyIcon}
        />
      </Text>
      {showPrivacySummary && (
        <Flex sx={styles.paragraphContainer}>
          <Text as="p">
            {t('Home.privacyPolicyBlurb')}{' '}
            <Text
              as="a"
              href="https://www.digitalhumans.com/privacy"
              target="_blank"
              rel="noreferrer noopener"
              sx={styles.privacyPolicyLink}
            >
              {t('Home.privacyPolicy')}
            </Text>{' '}
            .
          </Text>
        </Flex>
      )}
    </>
  )
}

const SophieVideo = () => {
  const width = useWindowWidth()
  const smallScreen = width < 1024

  return (
    <video
      playsInline
      autoPlay
      muted
      loop
      poster={smallScreen ? KateIdlePortraitPoster : KateIdlePoster}
    >
      <source
        src={
          smallScreen
            ? 'https://kate.digitalhumans.com/kate-idle-720-portrait-v2.mp4'
            : 'https://kate.digitalhumans.com/kate-idle-720-v2.mp4'
        }
        type="video/mp4"
      />
    </video>
  )
}

interface HomeProps {
  startSession: (token: string) => void
}

const Home: React.FC<HomeProps> = ({ startSession }) => {
  const { t, i18n } = useTranslation()
  const { isBrowserSupported } = useSupportedBrowser()
  const [showPasscode, setShowPasscode] = useState(false)
  const isSmallScreen = useIsSmallScreen()
  const [changingLanguage, setChangingLanguage] = useState(false)

  if (config.analyticsID) {
    ReactGA.pageview('/home')
  }

  if (showPasscode && !isBrowserSupported) {
    return <UnsupportedBrowser close={() => setShowPasscode(false)} />
  }

  const StartButton = ({ sx }: any) => (
    <Button
      variant="primary"
      onClick={trackHandler(startSession, 'lets-chat-btn')}
      sx={{ ...styles.letsChatButton, ...sx }}
    >
      {t('Home.letsChat')}
    </Button>
  )

  const getCountryLangCode = (countryCode: string) => {
    const filtered = Object.entries(config.languages)
      .map(([key, language]) => {
        if (language.code.split('-')[1] === countryCode) {
          return language.code
        }
      })
      .filter(item => item)
    const langCode = filtered[0]
    return langCode
  }

  const countries: string[] = []
  let languageLabels: any = {}

  Object.entries(config.languages)
    .map(([key, language]) => {
      let countryCode = language.code.split('-')[1] as string
      countries.push(countryCode)
      languageLabels[countryCode] = language.name
    })
    .filter(item => item)

  let defaultCurrentLanguage = () => {
    config.languages.find(obj => {
      return obj.code === config.default_language
    })
  }

  const currentLanguage: any = i18n.language
    ? i18n.language
    : defaultCurrentLanguage()

  const currentLanguageSplit = currentLanguage.split('-')
  const currentCountry = currentLanguageSplit[1] || currentLanguage

  if (changingLanguage) {
    return <Loading />
  }
  
  const onChangeLanguageFlags = (countryCode: string) => {
    const langCode: any = getCountryLangCode(countryCode)
    const prevLangCode = i18n.language
    // skip changing language if already set
    if (prevLangCode === langCode) {
      return
    }
    setChangingLanguage(true)
  
    i18n.changeLanguage(langCode).then(
      t => {
        setChangingLanguage(false)
        console.log('Successfully changed language to: ', langCode)
        const l = config.languages.find(e => e.code === langCode)
        // If there is a conversationId on Language object, set it, otherwise, English
        config.conversationId = l?.conversationId ? l.conversationId : '09a3b12d-8097-43fe-a026-ebbe5b106b68'
      },
      err => {
        setChangingLanguage(false)
        console.error('Something went wrong loading language, reverting', err)
        i18n.changeLanguage(prevLangCode)
      }
    )
  }

  return (
    <Flex sx={styles.mainContainer}>

      <Box sx={styles.langSelector}>
        <Label htmlFor="language">
          <span aria-label={t('region')}></span>
        </Label>

        {countries.length > 1 ? (
          <ReactFlagsSelect
            id="language"
            // name="language"
            countries={countries}
            customLabels={languageLabels}
            placeholder={t('Home.selectLanguage')}
            showSelectedLabel={false}
            showOptionLabel={true}
            selectedSize={isSmallScreen ? 12 : 24}
            selected={currentCountry}
            onSelect={onChangeLanguageFlags}
            fullWidth={true}
          />
        ) : (
          ''
        )}
      </Box>

      <Text sx={styles.mobileImSophie}>{t('Home.meet')} {config?.avatarName}</Text>
      <SophieVideo />

      <Box sx={styles.textContainer}>
        <Box sx={styles.titleContainer}>
          <Text sx={styles.imSophieText}>{t('Home.meet')} {config?.avatarName}</Text>
        </Box>
        <Box sx={styles.videoContainer}>
          <video
            autoPlay={false}
            loop={false}
            playsInline={true}
            controls={true}
            style={{ width: '388px', objectFit: 'cover' }}
            poster={KateIntroPoster}
          >
            <source
              src="https://kate.digitalhumans.com/kate-intro-720-v2.mp4"
              type="video/mp4"
            />
            {t('Home.loading')}
          </video>
        </Box>
        <Text sx={styles.subtitle}>
          {t('Home.iAmKateClick')}<br />
          {t('Home.askMeAnything')}
        </Text>
        <StartButton sx={styles.startButton} />

        <Flex
          sx={{
            ...styles.paragraphContainer
          }}
        >
          <Paragraphs />
        </Flex>
        <StartButton
          sx={{ display: ['none', 'none', 'none', 'none', 'block', 'block'] }}
        />

        <Heading sx={styles.partnersHeading}>{t('Home.ourPartners')}</Heading>

        <Flex sx={styles.partnersContainer}>
          <Flex as="section" sx={styles.partnerSection}>
            <Image sx={styles.wolframAlphaLogo} src={WolframAlphaLogo} />
            <Text as="p">
              <Link href="https://www.wolframalpha.com/" target="_blank">
                {t('Home.wolframAlpha')}
              </Link>{' '}
              {t('Home.wolframAlphaBlurb')}
            </Text>
          </Flex>
        </Flex>
      </Box>
    </Flex>
  )
}

export default Home