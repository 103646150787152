import { DigitalHuman, PasscodeOverlay } from 'uneeq-react-ui'
import React, { useState, useRef } from 'react'
import { useCookies } from "react-cookie";
import { v4 as uuidv4 } from 'uuid';
import { Box, Button } from 'rebass'

import { ThemeProvider } from 'emotion-theming'

import theme from '../theme'
import config from '../config'
import Home from './Home'
import assets from './assets'

import { testState } from 'uneeq-react-core'
import SessionTimedOut from './SessionTimedOut'

const backgroundStyle = {
  label: 'wrapper',
  width: '100%',
  minHeight: '100%',
  backgroundSize: 'cover',
  backgroundPosition: 'right bottom',
  backgroundColor: '#000000',
  backgroundImage: [
    'none',
    'none',
    'none',
    'none',
    `url('/background.png')`,
    `url('/background.png'})`
  ],
  overflow: 'hidden',
  position: 'absolute'
}

const loadingTips = [
  {
    title: 'Kate was created by UneeQ to explain digital humans to the world',
    showOnDesktop: true,
    showOnMobile: true
  },
  {
    title: "Kate's brain is composed of a number of advanced AI services so that she can interact conversationally",
    showOnDesktop: true,
    showOnMobile: true
  },
  {
    title:
      'Kate likes cool, dry weather.  She really hates the rain',
    showOnDesktop: true,
    showOnMobile: true
  },
  {
    title: 'Kate can answer questions on a wide variety of topics - quiz her!',
    showOnDesktop: true,
    showOnMobile: true
  },
  {
    title:
      'Conversational AI does not mean that the AI knows every answer - it means the AI can understand questions',
    showOnDesktop: true,
    showOnMobile: true
  },
  {
    title:
      "Kate has never eaten a chocolate chip cookie, no matter how many times we have offered",
    showOnDesktop: true,
    showOnMobile: true
  },
  {
    title:
      'Kate thinks of Sophie as her older sister, even though they are not related, because they are not real',
    showOnDesktop: true,
    showOnMobile: true
  },
  {
    title: 'Kate has an evolving personality that will be affected by the sum of her experiences',
    showOnDesktop: true,
    showOnMobile: true
  },
  {
    title: 'Kate has never received a Nobel Prize for anything',
    showOnDesktop: true,
    showOnMobile: true
  },
  {
    title: 'Kate treats factual inquiries very seriously, she will give very thorough answers',
    showOnDesktop: true,
    showOnMobile: true
  },
  {
    title: 'Kate does not wear socks, because she does not have feet',
    showOnDesktop: true,
    showOnMobile: true
  },
  {
    title: 'The team at UneeQ let me run wild with these loading page comments, but it is lunchtime so I ran out of clever things to write',
    showOnDesktop: true,
    showOnMobile: true
  }
]

// example CustomFeedback component that can be passed to DigitalHuman
// to customise the look and feel of this screen
const CustomFeedback = ({ restart, close, isOpen }: any) => {
  if (!isOpen) return null
  return (
    <Box
      sx={{
        background: 'white',
        width: '50vh',
        height: '50vh',
        position: 'absolute',
        top: 0,
        left: 0,
        zIndex: 20
      }}
    >
      CustomFeedback
      <Button onClick={close}>Close</Button>
      <Button onClick={restart}>Restart</Button>
    </Box>
  )
}

const App = () => {
  const [cookies, setCookie] = useCookies(["uneeq-kate-bdr"]);

  const handleCookie = () => {
    setCookie("uneeq-kate-bdr", uuidv4(), {
      path: "/"
    });
  }
  // For faster testing, skip straight to digitalHuman
  const [step, goTo] = useState(testState ? 'digitalHuman' : 'welcome')

  // const postInit = (uneeq: Uneeq) => {
  //   console.info('initialized')
  //   uneeq.sendTranscript('Example using initialized uneeq instance')
  // }

  // passcode is an overlay, not a step
  const [showPasscode, setShowPasscode] = useState(config.showPasscodeDefault)

  const tokenRef = useRef<string>()
  const start = () => {
    if(cookies['uneeq-kate-bdr'] === undefined || cookies['uneeq-kate-bdr'].length < 36){
      handleCookie()
    }
    // Show passcode overlay or go straight to digitalHuman
    if (config.usePasscode) {
      setShowPasscode(true)
    } else {
      goTo('digitalHuman')
    }
  }

  const startWithToken = (token: string) => {
    tokenRef.current = token
    goTo('digitalHuman')
    setShowPasscode(false)
  }

  const restart = () => goTo('welcome')

  return (
    <ThemeProvider theme={theme}>
      <Box
        sx={{
          ...backgroundStyle,
          overflow: step === 'welcome' ? 'visible' : 'hidden'
        }}
      >
        {step === 'digitalHuman' ? (
          <DigitalHuman
            assets={assets}
            config={config}
            token={tokenRef.current}
            loadingTips={loadingTips}
            onTimedOut={() => goTo('timed-out')}
            // postInit={postInit}
            restart={restart}
            // CustomFeedback={CustomFeedback} // uncomment to enable custom Feedback screen
            onSessionEnded={() => goTo('restart')}
          />
        ) : step === 'timed-out' ? (
          <SessionTimedOut restart={restart} />
        ) : (
          <Home startSession={start} />
        )}
        {showPasscode && (
          <PasscodeOverlay
            dismissable={false}
            start={startWithToken}
            close={() => setShowPasscode(false)}
            config={config}
          />
        )}
      </Box>
    </ThemeProvider>
  )
}

export default App
