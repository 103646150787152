import React, { useContext, useEffect, useRef, useState } from 'react'
import { Box, Button, Flex, Text } from 'rebass'
import {
  UneeqContext,
  useUneeqSpaceToTalk,
  useUneeqState,
  useIsSmallScreen,
  trackHandler
} from 'uneeq-react-core'
import { ThemeProvider } from '@material-ui/core/styles'
import ToggleButton from '@material-ui/lab/ToggleButton'
import { ReactComponent as ChatIcon } from '../../assets/img/keyboard-icon.svg'
import { ReactComponent as TalkIcon } from '../../assets/img/mic-icon.svg'
import { ReactComponent as SkipIcon } from '../../assets/img/skip.svg'
import { ReactComponent as ChatBubbleIcon } from '../../assets/img/transcript-chat-bubble.svg'
import InputProblem from '../InputProblem'
import Chat from './Chat'
import PushToTalk from './PushToTalk'
import styles from './styles'
import UserQuestion from './UserQuestion'
import { motion, AnimatePresence } from 'framer-motion'
import SavedItems from '../OnScreenInfo/SavedItems'
import SuggestedResponses from '../OnScreenInfo/SuggestedResponses'
import MobileInformationButton from '../OnScreenInfo/MobileInformationButton'
import { createMuiTheme } from '@material-ui/core/styles'
import ClosedCaptionIcon from '@material-ui/icons/ClosedCaption'
import { useTranslation } from 'react-i18next'
import Tooltip from '@material-ui/core/Tooltip'
import Captions from './Captions'
import ThinkingLoader from './ThinkingLoader'
const MotionUserQuestion = motion.custom(UserQuestion)
const MotionCaptions = motion.custom(Captions)
const MotionBox = motion.custom(Box)

interface InputToggleProps {
  speechMode: boolean
  toggleInputMode: () => void
}

const muiTheme = createMuiTheme({
  palette: {
    type: 'dark',
    primary: {
      main: '#ffffff'
    },
    secondary: {
      main: '#ffffff'
    },
    contrastThreshold: 3,
    tonalOffset: 0.2
  }
})

const InputToggle: React.FC<InputToggleProps> = ({
  speechMode,
  toggleInputMode
}) => {
  const { t } = useTranslation()
  return (
    <Button
      sx={{
        ...styles.toggleInputModeButton,
        display: speechMode
          ? 'flex'
          : ['none', 'none', 'none', 'none', 'flex', 'flex']
      }}
      variant="primaryInverted"
      onClick={toggleInputMode}
    >
      {speechMode ? <ChatIcon /> : <TalkIcon />}
      <Text sx={styles.inputToggleText} as="span">
        {speechMode
          ? t('BottomBar.BottomBar.type')
          : t('BottomBar.BottomBar.talk')}
      </Text>
    </Button>
  )
}

const BottomBar: React.FC = () => {
  const { recording, sending } = useUneeqSpaceToTalk()

  const {
    answer,
    inputMode,
    transcriptOpen,
    transcriptHasOpened,
    onScreenInfo,
    spacebarTapped,
    noInput,
    question,
    avatarSpeaking,
    savedItems,
    hiddenUI,
    thinking
  } = useUneeqState()
  const { dispatch, config } = useContext(UneeqContext)
  const { t } = useTranslation()

  const speechMode = config.sendLocalAudio && inputMode === 'speech'

  const toggleInputMode = () =>
    dispatch({ type: 'setInputMode', payload: speechMode ? 'text' : 'speech' })
  const openTranscript = () =>
    dispatch({ type: 'openTranscript', payload: true })

  const inputError = spacebarTapped ? 'spacebarTapped' : noInput && 'noInput'

  const shouldShowQuestion =
    !hiddenUI && question && !onScreenInfo?.suggestedResponses?.chosenResponse

  const trimmedQuestion =
    question?.length > 267 ? question.substring(0, 267).concat('...') : question

  const inputModeContainer = useRef()
  const isSmallScreen = useIsSmallScreen()

  const disableContextMenu = (event: any) => {
    event.preventDefault()
    event.stopPropagation()
    return false
  }

  const [captionsEnabled, setCaptionsEnabled] = useState(
    localStorage.getItem('captionsEnabled') === 'true' ? true : false
  )

  //const [captionsEnabled, setCaptionsEnabled] = useCaptionsEnabled()

  const onToggleCaptions = () => {
    const newValue = !captionsEnabled
    setCaptionsEnabled(newValue)
    localStorage.setItem('captionsEnabled', newValue.toString())
  }

  const onDisableContextMenu = (event: any) => {
    console.log(event)
    event.preventDefault()
    //event.stopPropagation()
  }

  return (
    <Flex sx={styles.bar}>
      <Flex sx={styles.left} />
      <Flex sx={styles.userQuestionMotionContainer}>
        <AnimatePresence>
          {((!hiddenUI && isSmallScreen) || captionsEnabled) &&
            answer &&
            avatarSpeaking && (
              <MotionCaptions
                variants={{
                  start: {
                    opacity: 0,
                    transition: {
                      delay: 0
                    },
                    transform: 'translateX(-200px)'
                  },
                  end: {
                    opacity: 1,
                    transition: {
                      delay: 0
                    },
                    transform: 'translateX(1px)'
                  },
                  final: {
                    opacity: 0,
                    transform: 'translateX(200px)'
                  }
                }}
                initial="start"
                animate="end"
                exit="final"
                key="question"
                captions={answer}
              />
            )}
        </AnimatePresence>
        <AnimatePresence>
          {shouldShowQuestion && (
            <MotionUserQuestion
              variants={{
                start: {
                  opacity: 0,
                  transition: {
                    delay: 1.5
                  },
                  transform: 'translateX(-200px)'
                },
                end: {
                  opacity: 1,
                  transition: {
                    delay: 1.5
                  },
                  transform: 'translateX(1px)'
                },
                final: {
                  opacity: 0,
                  transform: 'translateX(200px)'
                }
              }}
              initial="start"
              animate="end"
              exit="final"
              key="question"
            >
              {trimmedQuestion}
            </MotionUserQuestion>
          )}
        </AnimatePresence>
      </Flex>

      {
        <Flex
          sx={{
            ...styles.center,
            backgroundColor: ['secondary', 'secondary', 'transparent'],
            alignItems: 'center'
          }}
        >
          {!hiddenUI && (
            <AnimatePresence>
              {onScreenInfo?.suggestedResponses && (
                <MotionBox sx={styles.motionSuggestedResponses}>
                  <SuggestedResponses />
                </MotionBox>
              )}
            </AnimatePresence>
          )}

          {!hiddenUI &&
          avatarSpeaking &&
          onScreenInfo?.nextSuggestedResponses?.suggestedResponses?.length >
            0 ? (
            <Button
              variant="primaryInverted"
              sx={styles.skip}
              onClick={() => dispatch({ type: 'skip' })}
            >
              <SkipIcon />
              <span>Show next steps</span>
            </Button>
          ) : thinking ? (
            <Flex
              sx={{
                ...styles.pttOuterContainer,
                mb: [0, 0, 25, 25, 35]
              }}
            >
              <ThinkingLoader />
            </Flex>
          ) : (
            <Flex
              sx={{
                ...styles.pttOuterContainer,
                borderBottom: hiddenUI
                  ? 'none'
                  : ['none', 'none', '1px solid white']
              }}
            >
              {!hiddenUI && isSmallScreen ? (
                <>
                  <MobileInformationButton />
                </>
              ) : (
                <></>
              )}

              <Flex sx={styles.chatAndInputToggleContainer}>
                <Box sx={styles.toggleButtonContainer}>
                  <ThemeProvider theme={muiTheme}>
                    <Tooltip title="Toggle captions" aria-label="add">
                      <ToggleButton
                        style={styles.toggleButton}
                        value="captionsEnabled"
                        selected={captionsEnabled}
                        onChange={trackHandler(
                          onToggleCaptions,
                          captionsEnabled
                            ? 'caption-toggle-btn-off'
                            : 'caption-toggle-btn-on'
                        )}
                        size="small"
                      >
                        <ClosedCaptionIcon />
                      </ToggleButton>
                    </Tooltip>
                  </ThemeProvider>
                </Box>
                {!hiddenUI && (
                  <>
                    {isSmallScreen ? (
                      <Flex
                        sx={styles.mobileChatContainer}
                        ref={inputModeContainer}
                      >
                        <Chat />
                      </Flex>
                    ) : (
                      <Flex sx={styles.bigScreenChatContainer}>
                        <Chat />
                      </Flex>
                    )}
                  </>
                )}
                {!hiddenUI && config.sendLocalAudio && (
                  <Box onContextMenu={onDisableContextMenu}>
                    <PushToTalk />
                  </Box>
                )}
              </Flex>
            </Flex>
          )}
        </Flex>
      }
      <Box
        sx={{
          ...styles.right,
          display: speechMode
            ? 'flex'
            : ['none', 'none', 'none', 'none', 'flex', 'flex']
        }}
      />

      <Flex sx={styles.chatSavedItemsContainer}>
        {savedItems.length > 0 && <SavedItems />}
        {transcriptHasOpened && !transcriptOpen && (
          <Box onClick={openTranscript} sx={styles.chatBubbleContainer}>
            <ChatBubbleIcon />
            <span>{t('BottomBar.BottomBar.showChat')}</span>
          </Box>
        )}
      </Flex>

      {inputError && <InputProblem error={inputError} />}
    </Flex>
  )
}

export default BottomBar
